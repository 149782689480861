import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// import FilterModule from '@components/filterModule'
import Container from '@objects/container'
import _ from 'lodash'
// import Grid from '@material-ui/core/Grid'
// import { Pagination } from '@material-ui/lab'
// import InViewAnimation from '@objects/inViewAnimation'
// import MaterialShopItem from './materialShopItem'
import {
  materialShopSetOrderInCartItems,
  $materialShopMeta,
} from '@services/stores/materialShop'
import Copy from '../copy'

export type MaterialShopProps = RvG.IReactDefaultProps & {
  titleInternal: string
  items: Array<RvG.Contentful.IContentfulModuleMaterialShopItem>
  copy: RvG.Contentful.BasicRichTextType
  contact: RvG.Contentful.BasicRichTextType
}

interface IMaterialShopCategory {
  identifier:
    | RvG.Contentful.IContentfulMaterialShopCategory['identifier']
    | 'all'
  name: string
  entries: Array<RvG.Contentful.IContentfulModuleMaterialShopItem>
}

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    position: 'relative',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  root: {
    paddingBottom: theme.spacing(20),
  },
  infos: {
    paddingTop: 0,
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      paddingBottom: 0,
      marginBottom: theme.spacing(15),
    },
  },
  infoItem: {
    '&  p': {
      margin: 0,
    },
    '& + $infoItem': {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      '& + $infoItem': {
        marginTop: 0,
        marginLeft: theme.spacing(4),
      },
    },
  },
  downloadGrid: {
    marginBottom: theme.spacing(4),
    '& $downloadGridItem': {},
  },
  downloadGridItemWrapper: {},
  downloadGridItem: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '244px',
    },
  },
  backdrop: {
    position: 'absolute',
    height: 'calc(100% - 600px)',
    width: 'calc(100%)',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.light,
    zIndex: -1,
  },
}))

export default function MaterialShop({
  className,
  items = [],
  copy,
  contact,
}: MaterialShopProps): ReactElement {
  const itemsPerPage = 12
  const classes = useStyles()
  const gridRef = useRef(null)
  const categories = useMemo(() => {
    const acc = items.reduce((acc, cur) => {
      const category = _.find(acc, (cat) => {
        return cat.identifier === cur.category.identifier
      })
      if (category) {
        category.entries.push(cur)
      } else {
        acc.push({
          ...cur.category,
          entries: [cur],
        })
      }
      return acc
    }, [] as Array<IMaterialShopCategory>)
    acc.unshift({
      identifier: 'all',
      name: 'Alle',
      entries: items,
    })
    return acc
  }, [items])
  const [currentCategory, setCurrentCategory] =
    useState<IMaterialShopCategory | null>(categories[0])
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState<number>(
    currentCategory?.entries
      ? Math.ceil(currentCategory?.entries.length / itemsPerPage)
      : 0
  )
  const [backgroundOffset, setBackgroundOffset] = React.useState<number | null>(
    100
  )

  const filters = useMemo(() => {
    return categories.map((entry) => {
      return {
        id: entry.identifier,
        label: entry.name,
      }
    })
  }, [categories])

  const currentItems = useMemo(() => {
    const offset = (page - 1) * itemsPerPage
    if (currentCategory?.entries) {
      return currentCategory.entries.slice(offset, offset + itemsPerPage)
    } else {
      return []
    }
  }, [page, currentCategory])

  useEffect(() => {
    const handleResize = _.debounce(() => {
      // calculate offset of background to 50% first element image height (+ margintop)
      const current = gridRef?.current as any
      if (current && current?.children?.length) {
        const img = current.children?.[0].querySelector('.gatsby-image-wrapper')
        if (img) {
          const offset = current.offsetTop + img.offsetHeight / 2 + 16
          setBackgroundOffset(offset)
        }
      }
    }, 200)

    materialShopSetOrderInCartItems(items.filter((item) => item.orderInCart))
    $materialShopMeta.set({
      contact: contact,
    })
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const updateCategory = (categoryId: string) => {
    const selected = _.find(categories, (entry) => {
      return entry.identifier === categoryId
    }) as IMaterialShopCategory

    setCurrentCategory(() => selected)
    setPageCount(() => {
      return selected?.entries
        ? Math.ceil(selected?.entries.length / itemsPerPage)
        : 0
    })
    setPage(() => 1)
  }

  const handlePagChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(() => value)
    if (gridRef.current) {
      function offset(el: any) {
        const rect = el.getBoundingClientRect(),
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const topVal = offset(gridRef.current).top - 60

      setTimeout(() => {
        window.scrollTo({
          top: topVal,
        })
      }, 200)
    }
  }

  return (
    <section className={classes.outerWrapper}>
      <Container
        className={clsx(classes.infos, className)}
        width={'lg'}
        type={'nomargin'}
      >
        <Copy richtext={copy} className={classes.infoItem} />
        <Copy richtext={contact} className={classes.infoItem} />
      </Container>
      {/* <Container
        className={clsx(classes.root, className)}
        width={'lg'}
        type={'nomargin'}
      >
        <InViewAnimation
          group={[
            <FilterModule
              key={'materialShopFilter'}
              type={'materialShop'}
              currentFilter={currentCategory?.identifier ?? 'all'}
              filterOptions={filters}
              onChange={updateCategory}
            />,
            currentCategory && <h2>{currentCategory.name}</h2>,
          ]}
        />
        <Grid container className={classes.downloadGrid} ref={gridRef}>
          {currentItems.map((item, i) => {
            return (
              <Grid
                className={classes.downloadGridItemWrapper}
                item
                xs={12}
                md={6}
                lg={3}
                key={`material-${item.id}`}
              >
                <InViewAnimation
                  style={{
                    transitionDelay: `${100 * (i % 4)}ms`,
                    height: '100%',
                  }}
                >
                  <MaterialShopItem
                    className={classes.downloadGridItem}
                    {...item}
                  />
                </InViewAnimation>
              </Grid>
            )
          })}
        </Grid>
        {pageCount > 1 && (
          <InViewAnimation>
            <Pagination
              boundaryCount={1}
              siblingCount={1}
              count={pageCount}
              page={page}
              onChange={handlePagChange}
            />
          </InViewAnimation>
        )}
      <div
        className={classes.backdrop}
        style={{ height: `calc(100% - ${backgroundOffset}px)` }}
      />
        </Container> */}
    </section>
  )
}
